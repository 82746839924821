<template lang="pug">
  div.policy
    div.container
      h1 {{ $t('return.title') }}
      .policy__container
        p {{ $t('return.lastUpdatedDate') }}
        p {{ $t('return.choosingEasyPONText') }}
        p {{ $t('return.thisPolicyText') }}
        h3 {{ $t('return.cancelTitle') }}
        p {{ $t('return.accordanceText') }}
        p(v-html="$t('return.cancelText', { email: `<a href='mailto:support@easypon.in' class='custom-link' target='_blank'>support@easypon.in</a>` })")
        ul
          div(v-html="$t('return.infoList')")
        h3 {{ $t('return.refundsTitle') }}
        p {{ $t('return.refundsText') }}
        p {{ $t('return.refundsNoteText') }}
        h3 {{ $t('return.exceptionsTitle') }}
        p {{ $t('return.exceptionsText') }}
        ul
          div(v-html="$t('return.exceptionsList')")
        h3 {{ $t('return.contact') }}
        p(v-html="$t('return.questionsText',{ email: `<a href='mailto:support@easypon.in' class='custom-link' target='_blank'>support@easypon.in</a>`, link: `<a href='https://easypon.in/${ $i18n.locale }/contact' class='custom-link' target='_blank'>https://easypon.in/${ $i18n.locale }/contact.</a>` })")
        p {{ $t('return.reserveText') }}
</template>

<script>
export default {
  name: 'ReturnPage',
  metaInfo () {
    return this.$metaDefault({ robots: 'noindex, follow' })
  }
}
</script>
